import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import CardItem from "./card-item"
import { Link } from "gatsby"

const Section = styled.section`
  background: ${colors.beige};
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: 30% 70%;
  }
`
const LeftContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    order: 2;
  }

  @media (max-width: 500px) {
    padding: 20px 30px;
  }
`
const RightContainer = styled.div`
  background: ${colors.grey};
  overflow: hidden;

  @media (max-width: 768px) {
    order: 1;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Welcome = styled.h3`
  text-transform: uppercase;
  font-size: 15px;
  color: ${colors.grey};
  font-family: "Work Sans";
  font-weight: 700;
  letter-spacing: 1.5px;
`
const Title = styled.h1`
  font-size: 7vw;
`
const Description = styled.p`
  opacity: 0.8;
  max-width: 400px;
`

const CallToActionContainer = styled.div`
  margin-top: 40px;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`
const Instruction = styled.h4`
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: 700;
  font-size: 16px;
`
const Cards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
`
const CTA = styled.a`
  font-family: "Works Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: black;
`

const SiteIntro = props => (
  <Section>
    <LeftContainer>
      <Welcome>Welcome to</Welcome>
      <Title>La Soledad Estate</Title>
      <Description>
        Our beautiful 25 acre, old coffee estate is perfect for your next
        outdoor or indoor event
      </Description>
      <CallToActionContainer>
        <Instruction>
          Let's start your booking online! What's the occasion?
        </Instruction>
        <Cards>
          {services.map((service, index) => (
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to={service.link}
            >
              <CardItem
                title={service.title}
                cover={service.cover}
                key={index}
              />
            </Link>
          ))}
        </Cards>
        <CTA href="#spaces">Explore our Spaces Gallery</CTA>
      </CallToActionContainer>
    </LeftContainer>
    <RightContainer>
      <Cover src="https://images.unsplash.com/photo-1517842287605-5ce2abec20c8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
    </RightContainer>
  </Section>
)

export default SiteIntro

const services = [
  {
    title: "Wedding",
    link: "/the-estate",
    cover:
      "https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
  },
  {
    title: "Party/Fete",
    link: "/the-estate",
    cover:
      "https://images.unsplash.com/photo-1517263904808-5dc91e3e7044?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  },
  {
    title: "Conference",
    link: "/the-ballroom",
    cover:
      "https://images.unsplash.com/photo-1503428593586-e225b39bddfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
  },
]
