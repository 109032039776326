import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import MainButton from "./main-button"
import { Link } from "gatsby"
import Gallery from "react-grid-gallery"

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 70px 50px;
  background: ${props => props.background && colors.beige};

  @media (max-width: 550px) {
    padding: 50px 30px;
  }
`
const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  max-width: 1200px;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
const DetailsContainer = styled.div`
  text-align: ${props => props.alignRight && "right"};
  order: ${props => props.alignRight && 1};
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.alignRight ? "flex-end" : "flex-start")};

  @media (max-width: 768px) {
    margin: ${props => (props.alignRight ? "30px 0 0 0" : "0 0 30px 0")};
  }

  @media (max-width: 500px) {
    align-items: center;
  }
`
const Title = styled.h2`
  font-size: 50px;
  font-weight: 900;
  /* color: ${colors.main}; */

  @media (max-width: 500px) {
    font-size: 35px;
    width: 100%;
  }
`
const Copy = styled.p`
  max-width: 500px;
  font-style: ${props => props.emphasis && "italic"};
  text-align: inherit;

  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 550px) {
    margin-bottom: 40px;
  }

  @media (max-width: 500px) {
  }
`
const GalleryContainer = styled.div`
  width: 100%;
  /* background: grey; */
  /* height: 300px; */
`
const CalloutTitle = styled.h5`
  text-transform: uppercase;
  color: ${colors.grey};
  margin-bottom: 10px;
  text-align: inherit;
  width: 100%;
`

const SpaceSection = ({
  title,
  copy,
  perfectFor,
  alignRight,
  background,
  link,
}) => {
  function returnThumbStyle() {
    return {
      objectFit: "cover",
      marginBottom: "0",
      height: "100%",
    }
  }

  return (
    <Container background={background}>
      <GridLayout>
        <DetailsContainer alignRight={alignRight}>
          <Title>{title}</Title>
          <Copy>{copy}</Copy>
          <CalloutTitle>Perfect for:</CalloutTitle>
          <Copy emphasis>{perfectFor}</Copy>
          <Link to={link}>
            <MainButton title="Book this space" />
          </Link>
        </DetailsContainer>
        <GalleryContainer>
          <Gallery
            thumbnailStyle={returnThumbStyle}
            enableImageSelection={false}
            margin={2}
            images={IMAGES}
          />
        </GalleryContainer>
      </GridLayout>
    </Container>
  )
}

export default SpaceSection

const IMAGES = [
  {
    src: require("../images/aerial.jpg"),
    thumbnail: require("../images/aerial.jpg"),
    thumbnailWidth: 1300,
    thumbnailHeight: 1625,
  },
  {
    src: require("../images/table.jpg"),
    thumbnail: require("../images/table.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },

  {
    src: require("../images/table-2.jpg"),
    thumbnail: require("../images/table-2.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },
  {
    src: require("../images/flowers.jpg"),
    thumbnail: require("../images/flowers.jpg"),
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },
  {
    src: require("../images/dining.jpg"),
    thumbnail: require("../images/dining.jpg"),
    thumbnailWidth: 934,
    thumbnailHeight: 1401,
  },
  {
    src:
      "https://images.unsplash.com/photo-1553102674-af685bb5fe40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
    thumbnail:
      "https://images.unsplash.com/photo-1553102674-af685bb5fe40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80",
    thumbnailWidth: 2000,
    thumbnailHeight: 1333,
  },
]
