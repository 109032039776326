import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import CardItem from "./card-item"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import MainButton from "./main-button"

const Section = styled(motion.section)`
  background: ${colors.beige};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 550px) {
    padding: 20px 40px;
  }
`

const Welcome = styled(motion.h3)`
  text-transform: uppercase;
  font-size: 15px;
  color: ${colors.grey};
  font-family: "Work Sans";
  font-weight: 700;
  letter-spacing: 1.5px;
`
const Title = styled(motion.h1)`
  font-size: 7vw;
  text-align: center;
  /* color: ${colors.main}; */

  @media (max-width: 550px) {
    font-size: 50px;
  }
`
const Description = styled(motion.p)`
  opacity: 1;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  color: #626262;
  font-size: 26px;
  line-height: 130%;
  margin-bottom: 40px;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`
const Logo = styled(motion.img)`
  width: 115px;

  @media (max-width: 550px) {
    width: 90px;
  }
`
const CTA = styled(motion.div)``
const ComingSoon = styled.p`
  font-size: 18px;
  letter-spacing: 2px;
  color: ${colors.main};
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  margin: 0;
`

const NewSiteIntro = props => (
  <Section
  // initial={{ backgroundColor: colors.beige }}
  // animate={{ backgroundColor: "#3F4940" }}
  // transition={{ delay: 5 }}
  >
    <Logo
      // initial={{ y: 50, opacity: 0 }}
      animate={{ y: [100, 100, 0], opacity: [0, 1, 1], scale: [1.5, 1.5, 0.9] }}
      transition={{ duration: 1 }}
      transition={{
        duration: 4,
        ease: "easeOut",
        // times: [0, 0.2, 0.5, 0.8, 1],
        // loop: Infinity,
        repeatDelay: 1,
      }}
      src={require("../images/icon-logo.png")}
    />
    <Welcome
      // initial={{ opacity: 0 }}
      initial={{ opacity: 0, y: 100 }}
      transition={{
        duration: 2.35,
        ease: "easeOut",
        delay: 2.1,
      }}
      animate={{ opacity: 1, y: 0 }}
    >
      Welcome to
    </Welcome>
    <Title
      initial={{ opacity: 0, y: 100 }}
      transition={{
        duration: 2.35,
        ease: "easeOut",
        delay: 2.1,
      }}
      animate={{ opacity: 1, y: 0 }}
    >
      La Soledad Estate
    </Title>
    <Description
      initial={{ opacity: 0, y: 100 }}
      transition={{
        duration: 2.35,
        ease: "easeOut",
        delay: 2.1,
      }}
      animate={{ opacity: 1, y: 0 }}
    >
      Our beautiful 25 acre, old coffee estate is perfect for your next outdoor
      or indoor event
    </Description>
    <CTA
      initial={{ opacity: 0, y: 100 }}
      transition={{
        duration: 2.35,
        ease: "easeOut",
        delay: 2.1,
      }}
      animate={{ opacity: 1, y: 0 }}
    >
      <ComingSoon>Our Website is coming soon.</ComingSoon>
    </CTA>
  </Section>
)

export default NewSiteIntro

const services = [
  {
    title: "Wedding",
    link: "/the-estate",
    cover:
      "https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
  },
  {
    title: "Party/Fete",
    link: "/the-estate",
    cover:
      "https://images.unsplash.com/photo-1517263904808-5dc91e3e7044?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  },
  {
    title: "Conference",
    link: "/the-ballroom",
    cover:
      "https://images.unsplash.com/photo-1503428593586-e225b39bddfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
  },
]
