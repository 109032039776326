import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"

const SectionTitleContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 45px 0;
  padding: ${props => props.margin && "5px 0"};

  @media (max-width: 500px) {
    padding: 30px 0;
  }
`
const Subtitle = styled.p`
  font-size: 14px;
  margin: 0 0 5px 0;
  line-height: 120%;
  font-weight: 600;
  color: ${colors.grey};

  @media (max-width: 500px) {
    font-size: 13px;
  }
`
const Title = styled.h1`
  font-size: 40px;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 30px;
  }
`

const SectionTitle = props => (
  <SectionTitleContainer margin={props.margin}>
    <Subtitle>{props.subtitle}</Subtitle>
    <Title>{props.title}</Title>
  </SectionTitleContainer>
)

export default SectionTitle
