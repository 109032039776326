import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  background: white;
  width: 150px;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;
`
const Cover = styled.img`
  width: 100%;
  height: 90px;
  object-fit: cover;
`
const Title = styled.h4`
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
`

const CardItem = ({ title, cover }) => (
  <Container
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ scale: { duration: 0.1 }, opacity: { duration: 0.6 } }}
    whileHover={{ scale: 1.05, y: -5 }}
  >
    <Cover src={cover} />
    <Title>{title}</Title>
  </Container>
)

export default CardItem
