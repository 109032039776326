import React from "react"
import styled from "styled-components"
import SectionTitle from "./section-title"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 50px;
`
const Copy = styled.p`
  text-align: center;
`

const AboutSection = props => (
  <Container>
    <SectionTitle
      title="The Story"
      subtitle="The history behind our beautiful estate"
    />
    <Copy>
      La Soledad was originally a 25 acre cocoa and coffee estate Old Spiers
      Estate. Nestled in Maracas Valley, the estate faces the Northern
      Range,with a birds eye view of El Tucuche.
    </Copy>
    <Copy>
      Enim neque volutpat ac tincidunt vitae semper. In cursus turpis massa
      tincidunt dui. Nibh sed pulvinar proin gravida hendrerit. Imperdiet nulla
      malesuada pellentesque elit eget gravida cum sociis. Egestas fringilla
      phasellus faucibus scelerisque eleifend donec pretium vulputate sapien.
      Malesuada bibendum arcu vitae elementum curabitur vitae. Sed turpis
      tincidunt id aliquet risus feugiat in ante. Ipsum consequat nisl vel
      pretium lectus quam id leo in.{" "}
    </Copy>
    <Copy>
      Mauris augue neque gravida in fermentum et sollicitudin. Pulvinar etiam
      non quam lacus suspendisse faucibus interdum posuere lorem. Quisque id
      diam vel quam elementum pulvinar etiam. Placerat in egestas erat imperdiet
      sed euismod nisi porta. Turpis egestas pretium aenean pharetra magna ac
      placerat. Magna fermentum iaculis eu non diam phasellus vestibulum lorem
      sed. Posuere morbi leo urna molestie at elementum eu. Mi in nulla posuere
      sollicitudin aliquam ultrices sagittis. Varius quam quisque id diam vel
      quam elementum pulvinar etiam.
    </Copy>
  </Container>
)

export default AboutSection
