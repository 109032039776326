import React from "react"

//COMPONENTS
import Layout from "../components/layout"
import SEO from "../components/seo"
import SpaceSection from "../components/space-section"
import SectionTitle from "../components/section-title"
import AboutSection from "../components/about-section"
import SiteIntro from "../components/site-intro"
import NewSiteIntro from "../components/new-site-intro"
import { Break } from "../style/typography"

const IndexPage = () => (
  <Layout home>
    <SEO title="Home" />
    <NewSiteIntro />
    {/* <SiteIntro /> */}
    {/* <SectionTitle
      id="spaces"
      title="Book Our Spaces"
      // subtitle="Wondering what we have to offer?"
    />
    <Break style={{ width: "20%", margin: "10px auto" }} />
    <SpaceSection
      title="The Estate"
      link="/the-estate"
      perfectFor="Weddings, Anniversaries, Graduations, Parties"
      copy="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus luctus accumsan tortor posuere ac ut consequat semper. Felis bibendum ut tristique et. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Sit amet massa vitae tortor condimentum lacinia quis vel eros."
    />
    <SpaceSection
      background
      alignRight
      link="/the-ballroom"
      title="The Ballroom"
      perfectFor="Seminars, Company Oritentation, Business Presentations"
      copy="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus luctus accumsan tortor posuere ac ut consequat semper. Felis bibendum ut tristique et. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Sit amet massa vitae tortor condimentum lacinia quis vel eros."
    />
    <AboutSection /> */}
  </Layout>
)

export default IndexPage
